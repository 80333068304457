import React from 'react';
import classes from './RunningLine.module.css';
import { useTranslation } from 'react-i18next';
import circle from '../../images/svg/circle-carousel.svg';

export default function RunningLineItem() {
  const { t } = useTranslation();

  return (
    <>
      <li className={classes.runningItem}>
        <img src={circle} alt=''/>
        {t('DorToDoor')}
      </li>
      <li className={classes.runningItem}>
        <img src={circle} alt=''/>
        {t('GermanWideShiping')}
      </li>
      <li className={classes.runningItem}>
        <img src={circle} alt=''/>
        {t('FreeTransport')}
      </li>
      <li className={classes.runningItem}>
        <img src={circle} alt=''/>
        {t('TypicalRepairTime')}
      </li>
    </>
  );
}
