import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import orangeBackground from '../../assets/orange-background.svg';
import { useTranslation } from 'react-i18next';
import decorateImage from '../../assets/decorate-image.png';

const useStyles = makeStyles((theme) => {
  const s = (x) => theme.spacing(x);
  return {
    userWrapper: {
      padding: '40px 0 0 0',
      display: 'flex',
      alignItems: 'center',
      gap: '20px',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    repairLink: {
      display: 'block',
      width: '100%',
      maxWidth: '350px',
      textAlign: 'center',
      fontSize: s(2),
      fontWeight: 700,
      color: '#ffffff',
      backgroundImage: `url(${orangeBackground})`,
      backgroundSize: 'cover',
      padding: `${s(1.5)}px ${s(0)}px`,
      transition: '0.4s ease-in-out',
      '&:hover': {
        color: '#ffffff',
        scale: 1.1,
      },
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
      },
    },
    rightPicture: {
      height: '440px',
      maxWidth: '100%',
      objectFit: 'contain',
    },
    leftBlock: {
      flex: '0 0 60%',
      display: 'flex',
      flexDirection: 'column',
      gap: '30px',
      [theme.breakpoints.down('sm')]: {
        marginBottom: '80px',
      },
    },
    leftText: {
      color: '#ffffff',
      lineHeight: '30.5px',
    },
    title: {
      fontSize: s(3.5),
      margin: '25px 0',
      color: '#ffffff',
      paddingLeft: '20px',
    },
    rightBlock: {
      flex: '0 0 50%',
    },
    optionsList: {
      display: 'flex',
      flexDirection: 'column',
      gap: '25px',
      listStyleType: 'none',
      padding: '0',

      '& li': {
        color: '#ffffff',
        position: 'relative',
        fontWeight: '500',
        fontSize: '18px',
        paddingLeft: '20px',
      },

      '& li::before': {
        content: '"✓"',
        position: 'absolute',
        left: 0,
        color: '#ffffff',
      },
    },
    footerTitle: {
      fontSize: s(3.5),
      color: '#ffffff',
      marginTop: '65px',
    },
  };
});

const TailorSection = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div>
      <div className={classes.userWrapper}>
        <div className={classes.rightBlock}>
          <Typography component={'h2'} className={classes.title}>
            {t('You Are')}
          </Typography>
          <ul className={classes.optionsList}>
            <li>{t('You are p-1')}</li>
            <li>{t('You are p-2')}</li>
            <li>{t('You are p-3')}</li>
            <li>{t('You are p-4')}</li>
          </ul>
        </div>
        <div className={classes.leftBlock}>
          <img src={decorateImage} alt="decorate" className={classes.rightPicture} />
        </div>
      </div>
      <div>
        <a href="mailto:repairpartners@repair-rebels.com " className={classes.repairLink}>
          {t('GET IN TOUCH')}
        </a>
      </div>
      <Typography component={'h2'} className={classes.footerTitle}>
        {t('...BECAUSE THERE IS NO CIRCULAR FASHION WITHOUT CARE & REPAIR!')}
      </Typography>
    </div>
  );
};

export default TailorSection;
