import { Typography, makeStyles } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import orangeBackground from '../../assets/orange-background.svg';
import teamsImage from '../../assets/teams.png';
import i18next from 'i18next';

const useStyles = makeStyles((theme) => {
  const s = (x) => theme.spacing(x);
  const de = i18next.language === 'de';
  return {
    introBlock: {
      marginBottom: '40px',
    },
    repairLink: {
      width: '100%',
      textTransform: 'uppercase',
      maxWidth: '272px',
      textAlign: 'center',
      fontSize: s(2),
      fontWeight: 700,
      color: '#ffffff',
      backgroundImage: `url(${orangeBackground})`,
      backgroundSize: 'cover',
      padding: `${s(1.5)}px ${s(0)}px`,
      marginTop: de ? '70px' : 'auto',
      transition: '0.4s ease-in-out',
      '&:hover': {
        color: '#ffffff',
        scale: 1.1,
      },
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
        margin: '70px 0',
      },
    },
    textBlock: {
      marginTop: '20px',
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    rightPicture: {
      width: '100%',
      height: '440px',
      maxWidth: '100%',
      objectFit: 'contain',
    },
    leftBlock: {
      flex: '0 0 60%',
      display: 'flex',
      flexDirection: 'column',
      gap: '5px',
    },
    leftText: {
      color: '#ffffff',
      lineHeight: '30.5px',
    },
    brandTitle: {
      fontSize: s(4),
      marginBottom: '5px',
    },
    subTitle: {
      fontSize: s(3.4),
      color: '#ffffff',
      marginBottom: '5px',
    },
    rightBlock: {
      flex: '0 0 50%',
      display: 'flex',
      alignItems: de ? 'start' : 'flex-end'
    },
  };
});

const IntroBlock = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.introBlock}>
      <div className={classes.textBlock}>
        <div className={classes.leftBlock}>
          <Typography component={'h2'} className={`${classes.brandTitle} with-bar`}>
            {t('CraftsmenCooperations')}
          </Typography>
          <Typography component={'h2'} className={classes.subTitle}>
            {t('Join Network')}
          </Typography>
          <Typography component={'p'} className={classes.leftText}>
            {t('Craftsmen Page Main text p1')}
          </Typography>
          <Typography component={'p'} className={classes.leftText}>
            {t('Craftsmen Page Main text p2')}
          </Typography>
          <a href="mailto:repairpartners@repair-rebels.com " className={classes.repairLink}>
            {t('GET IN TOUCH')}
          </a>
        </div>
        <div className={classes.rightBlock}>
          <img src={teamsImage} alt="teams" className={classes.rightPicture} />
        </div>
      </div>
    </div>
  );
};

export default IntroBlock;
