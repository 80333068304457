import ClubApi from '../interfaces/api';

import API_SERVICE from '../../api/api.service';
import appRequest from 'utils/request';

async function invite(email: string, list: string): Promise<{ success: boolean; message: string }> {
  const requestBody = {
    email: email,
    list: list
  };
  return await API_SERVICE.mailerController.invite.call({ requestBody });
}

/**
 * Club API definitions for production use.
 *
 * These definitions can only be covered by end-to-end (e2e) tests.
 */
async function acceptInvite(emailToken: string, list: string): Promise<{ success: boolean; message: string }> {
  return await API_SERVICE.mailerController.accept.call({ parameters: { emailToken, list } });
}

async function registerWithDiscount(email: string): Promise<{ success: boolean; message: string }> {
  const requestBody = {
    email: email,
  };
  const { data } = await appRequest('/promotions/register-with-discount', {
    method: 'POST',
    data: requestBody,
  })
  return data;
}

const clubApiProd: ClubApi = {
  invite,
  registerWithDiscount,
  acceptInvite,
};

export default clubApiProd;
