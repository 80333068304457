import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import BrandsItem from '../BrandsItem/BrandsItem';
import { useTranslation } from 'react-i18next';
import expertiseImage from '../../assets/picture 2 - RR expertise.png';
import i18next from 'i18next';
import RunningPartnershipsLine from '../RunningLine';
import BrandsRuningStroke from '../BrandsRuningStroke/BrandsRuningStroke';
import { craftsmenBenefitsDataDe, craftsmenBenefitsDataEn } from 'CraftsmenSubpage/data/craftsmenBenefitsData';
import HowRepairsWorks from '../HowItWorks/HowItWorks';
import { Link } from '@reach/router';
import { getPage } from 'utils/pages';
import arrow from '../../assets/arrow.png';

const useStyles = makeStyles((theme) => {
  const s = (x) => theme.spacing(x);
  return {
    brandsWrapper: {
      background: '#ffffff',
      paddingBottom: '30px',
    },
    brandsInner: {
      marginBottom: '45px',
    },
    featuresList: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gap: '5px',
      alignItems: 'center',
      listStyle: 'none',
      padding: '40px 0',
      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: '1fr',
        marginBottom: '0',
        gap: '10px',
      },
    },
    quoteText: {
      fontSize: s(2.5),
      textTransform: 'none',
      textAlign: 'center',
      color: '#EB672C',
      margin: '20px 0 10px 0',
    },
    quoteSignature: {
      textAlign: 'center',
      fontSize: '16px',
      color: '#EB672C',
      maxWidth: '400px',
      margin: '0 0 80px auto',
    },
    runningLine: {
      marginBottom: '80px',
    },
    expertiseSection: {
      paddingBottom: '20px',
    },
    expertiseText: {
      maxWidth: '550px',
      fontSize: '18px',
      marginLeft: 'auto',
      marginBottom: '30px',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'left',
      },
    },
    link: {
      color: '#000000',
      fontSize: '18px',
      fontWeight: 700,
      transition: '0.3s ease-in-out',
      '&:hover': {
        color: '#EB672C',
      },
    },
    tailorsLinkBlock: {
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
      alignItems: 'end',
    },
    arrowImg: {
      maxWidth: '100%',
      width: '150px',
    },
    expertiseWrapper: {
      display: 'flex',
      alignItems: 'center',
      gap: '20px',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    leftBlock: {
      flex: '0 0 50%',
    },
    rigthBlock: {
      flex: '0 0 50%',
      textAlign: 'right',
    },
    aboutTitle: {
      fontSize: '24px',
      marginBottom: '20px',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
    },
    leftPicture: {
      height: '440px',
      maxWidth: '100%',
      objectFit: 'contain',
    },
  };
});

const BrandsPartnerships = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const brandsData = i18next.language === 'en' ? craftsmenBenefitsDataEn : craftsmenBenefitsDataDe;

  return (
    <div className={classes.brandsWrapper}>
      <div className={`${classes.brandsInner} container`}>
        <BrandsRuningStroke />
        <ul className={classes.featuresList}>
          {brandsData.map((feature, index) => (
            <BrandsItem key={index} {...feature} />
          ))}
        </ul>
        <div>
          <Typography component={'h2'} className={classes.quoteText}>
            {t('PageQuote')}
          </Typography>
          <p className={classes.quoteSignature}>{t('QuoteSignature')}</p>
        </div>
      </div>
      <div className={classes.runningLine}>
        <RunningPartnershipsLine />
      </div>
      <div className={`${classes.expertiseSection} container`}>
        <HowRepairsWorks />
        <div className={classes.expertiseWrapper}>
          <div className={classes.leftBlock}>
            <img src={expertiseImage} alt="expertise" className={classes.leftPicture} />
          </div>
          <div className={classes.rigthBlock}>
            <Typography component={'h2'} className={classes.aboutTitle}>
              {t('ABOUT REPAIR REBELS')}
            </Typography>
            <p className={classes.expertiseText}>{t('Mission of Repair Rebels')}</p>
            <div className={classes.tailorsLinkBlock}>
              <Link to={getPage('tailorList')} className={classes.link}>
                {t('MEET OUR REPAIR HEROS')}
              </Link>
              <Link to={getPage('tailorList')}>
                <img className={classes.arrowImg} src={arrow} alt="arrow" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrandsPartnerships;
