import React from 'react';
import classes from './RunningLine.module.css';
import { useTranslation } from 'react-i18next';
import circle from '../../../images/svg/circle-carousel.svg';

export default function RunningLineItem() {
  const { t } = useTranslation();

  return (
    <>
      <li className={classes.runningItem}>
        <img src={circle} alt=''/>
        {t('RunningLineItem-1')}
      </li>
      <li className={classes.runningItem}>
        <img src={circle} alt=''/>
        {t('RunningLineItem-2')}
      </li>
      <li className={classes.runningItem}>
        <img src={circle} alt=''/>
        {t('RunningLineItem-3')}
      </li>
      <li className={classes.runningItem}>
        <img src={circle} alt=''/>
        {t('RunningLineItem-4')}
      </li>
      <li className={classes.runningItem}>
        <img src={circle} alt=''/>
        {t('RunningLineItem-5')}
      </li>
      <li className={classes.runningItem}>
        <img src={circle} alt=''/>
        {t('RunningLineItem-6')}
      </li>
    </>
  );
}
