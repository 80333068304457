import React from 'react';
import IntroBlock from './components/Intro/Intro';
import BrandsPartnerships from './components/Brands/Brands';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import TailorSection from './components/TailorSection/TailorSection';

const CraftsmenSubpage = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('Craftsmen - Cooperations')}</title>
        <meta name="description" content={t('partnership-description')} />
      </Helmet>
      <div className="container">
        <IntroBlock />
      </div>
      <div>
        <BrandsPartnerships />
      </div>
      <div className="container">
        <TailorSection />
      </div>
    </>
  );
};

export default CraftsmenSubpage;
