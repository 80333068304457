import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ecologo from '../../assets/ecodesign.png';
import landeshauptstadtLogo from '../../assets/landeshauptstadt.png';
import kulturLogo from '../../assets/kultur-und-kreativpiloten.png';
import SubscribeForm from '../SubscribeForm/SubscribeForm';

const useStyles = makeStyles((theme) => {
  return {
    wrapper: {
      marginBottom: '50px',
    },
    section: {
      textAlign: 'center',
      marginBottom: '40px',
    },
    title: {
      textAlign: 'center',
      marginBottom: '20px',
      fontSize: '32px',
    },
    logos: {
      display: 'flex',
      alignItems: 'center',
      gap: '100px',
      justifyContent: 'center',
      marginBottom: '50px',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        gap: '40px',
      },
    },
    logoImg: {
      maxWidth: '100%',
      width: '230px',
    },
  };
});

const HowRepairsWorks = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <section className={classes.wrapper}>
      <div className={classes.section}>
        <Typography component={'h2'} className={classes.title}>
          {t('HOW IT WORKS')}
        </Typography>
        <p>{t('How It Works p-1')}</p>
        <p>{t('How It Works p-2')}</p>
        <p>{t('How It Works p-3')}</p>
      </div>
      <div className={classes.logos}>
        <img src={ecologo} alt="eco-logo" className={classes.logoImg} />
        <img src={kulturLogo} alt="kultur-logo" width={'200px!important'} />
        <img src={landeshauptstadtLogo} alt="landeshauptstadt-logo" className={classes.logoImg} />
      </div>
      <SubscribeForm />
    </section>
  );
};

export default HowRepairsWorks;
