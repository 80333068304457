import appProductsI18nEn from './products/en';
import appTailorsI18nEn from './tailors/en';
import seoI18nEn from './seo-en';

// const appI18nEn = {
//   'Unexpected error. Try again later or contact us': 'Unexpected error. Try again later or contact us',
//   'Order access error': "Order not found, or you don't have permission to edit it. Please check if you're logged in to your account or contact us!",
//   'Order rating access error': "Order not found, or you don't have permission to rate tailors. Please check if you're logged in to your account or contact us!",
//   'Not Found': 'Not Found',
//   aboutUs: '',
//   'Go back': 'Go back',
//   'Frequently Asked Questions': 'Frequently Asked Questions',
//   'Our Supporters': 'Our Supporters',
//   'Keep them and let us take care of them!': 'Keep them and let us take care of them!',
//   'Still in love with your favourites?': 'Still in love with your favourites?',
//   'Door to door deliveries': 'Door to door deliveries in',
//   'We pick it up and we deliver it back': 'we pick it up and we deliver it back',
//   'Tuesdays & Fridays': 'Tuesdays & Fridays',
//   'Pick ups and deliveries <1>only in Düsseldorf</1>': 'Pick ups and deliveries <1>only in Düsseldorf</1>',
//   'One week only!': 'One week only!',
//   'Get your garment back within a week': 'Get your garment back within a week',
//   'Loading...': 'Loading...',
//   'Get estimation': 'Get estimation',
//   'Get estimation description':
//     'No suitable offer or do you have a special repair? Request an estimate now. We would like to take care of every favorite piece and send you an individual offer.',
//   estimationDataPrivacy:
//     'I expressly consent to the collection and processing of my personal data for the purpose of estimating the costs arising from the requested repair service. All information provided is voluntary. I have taken note of the information on data protection, which is available at the <0>link</0>*',
//   ratingDataPrivacy:
//     'I expressly consent to the collection, processing and publication of my personal data for the purpose of evaluating performance. All information provided is voluntary. I have taken note of the information on data protection, which is available on the <0>link</0>*',
//   contactUsDataPrivacy:
//     'I expressly consent to the collection and processing of my personal data for contact purposes. I have taken note of the information on data protection, which is available at the <0>link</0>.',
//   "Let's get started!": "Let's get started!",
//   'Choose the item to repair': 'Choose the item you need to repair',
//   Dresses: 'Dresses',
//   Trousers: 'Trousers',
//   Tops: 'Tops',
//   Jackets: 'Jackets',
//   Sneakers: 'Sneakers',
//   Bags: 'Bags',
//   '<0><0>Good for the planet</0><1></1></0><1>& for your wallet</1>': '<0><0>Good for the planet</0><1></1></0><1>& for your wallet</1>',
//   'Collect CO2 Credits and get discounts': 'Collect CO2 Credits and get discounts',
//   'Learn More': 'Learn More',
//   'Try it now!': 'Try it now!',
//   '<0>Want to become a</0><1>Repair Rebel?</1>': '<0>Want to become a</0><1>Repair Rebel?</1>',
//   'Why <1></1> Repair Rebels?': 'Why <1></1> Repair Rebels?',
//   homePageLoyaltyClubBody:
//     'Get practical care and repair tips, exclusive offers and collect CO2 credits. Sign up now for our newsletter and become part of the Super Rebel Loyalty Club.',
//   homePageLoyaltyClubDescriptiveParagraphHeader: 'What you can expect',
//   homePageLoyaltyClubDescriptiveParagraph:
//     "Lots of useful tips & tricks on how to care for your beloved clothes, shoes and bags. Cool facts and information about new sustainable fashion brands and events in your neighbourhood. And of course, you will be rewarded for your loyalty and passion for repairs with discounts and loyalty points. Join us and don't miss out!",
//   homePageLoyaltyClubCallToAction: 'Sign up now for our newsletter and become part of the Super Rebel Loyalty Club.',
//   homePageLoyaltyClubQuote: 'YOU WANT TO BECOME A REAL REPAIR REBEL? THEN BECOME PART OF THE SUPER REBEL LOYALTY CLUB.',
//   'Check your inbox for the invite email': 'Check your inbox for the invitation email',
//   'Invite sent': 'Invitation sent',
//   'You are subscribed': "Hooray! You have successfully subscribed to the Super Rebel Loyalty Club's Newsletter.",
//   'Discounts & Packages': 'Discounts & Packages',
//   'Join the club': 'Join the club',
//   Back: 'Back',
//   'Our Rebels': 'Our Rebels',
//   'See More': 'See More',
//   'Our Tailors': 'Our Tailors',
//   'Meet our local partners': 'Meet our local partners',
//   homePageBlogIntro:
//     'Our local partners support our mission to give your clothes a second life, so you can wear them longer. Our carefully selected tailors, cobblers and goldsmiths are masters of their trade and show passion and love in every repair!',
//   'Contact Information': 'Contact Information',
//   'Contact Information - Bodytext 1':
//     'We are on a mission, to re-define the textile and shoe repairs industry in a comfortable, social and modern way! For the environment, fashionable rebels and local craft talents.',
//   'Contact Information - Contact Us': 'Contact us for more information:',
//   'Contact Information Estimation': 'Contact Information',
//   Imprint: 'Imprint',
//   'Privacy Policy': 'Privacy Policy',
//   'Terms and Conditions': 'Terms and Conditions',
//   // Detailed image button
//   from: 'from',
//   'Main services': 'Main services',
//   Address: 'Address',
//   'Name for the Address': 'Name for the Address',
//   'Zip code': 'MISSING',
//   'Address line': 'MISSING',
//   'Pictures of his work': 'Pictures of his work',
//   'Pictures of her work': 'Pictures of her work',
//   'Good for the planet': 'Good for the planet',
//   'Good for your wallet': 'Good for your wallet',
//   'Good for the local economy': 'Good for the local economy',
//   'CO2 Credits Per Garment': 'CO<sub>2</sub> Credits',
//   Garment: 'Garment',
//   'CO2 Credits': 'CO<sub>2</sub> Credits',
//   'Why CO2 Credits?': 'Why CO<sub>2</sub> Credits?',
//   CO2_page_body: 'MISSING', // TEXT NEEDED
//   'Start Repairing': 'Start Repairing',
//   'START REPAIRING': 'START REPAIRING',
//   'PRESS PACKAGE': 'PRESS PACKAGE',
//   'PRESS TEXT': 'WANT TO LEARN MORE ABOUT OUR WORK? CHECK OUT OUR PRESS PACKAGE WHERE YOU WILL FIND OUR PRESS RELEASES AND PICTURES',
//   'Join the Club': 'Join the Club',
//   'How It Works': 'How It Works',
//   Repairing: 'Repair',
//   'Tips & More': 'Tips & More',
//   'About Us': 'About Us',
//   Tailors: 'Our Tailors',
//   Contact: 'Contact',
//   'Select the item type': 'Select the item type', // used in tests
//   'Select the type of repair': 'Select the type of repair', // used in tests
//   'Further Details': 'Further Details', // used in tests
//   'Successfully added to cart': 'Successfully added to the cart!', // used in tests
//   'Select the type of service': 'Select the type of service',
//   'Send us your request': 'Send us your request',
//   'Add to Cart': 'Add to Cart',
//   Adding: 'Adding',
//   Comments: 'Comments',
//   'Type of Repair': 'Type of Repair',
//   'Item Type': 'Item Type',
//   product_estimation_hint:
//     "Did not find a suitable offer or do you have a very special repair? Request an estimate now. From grandma's coat to your camping tent, we will look at each request individually and select a suitable repair artist.",
//   'Garment Description': 'Garment Description',
//   'Describe your requests for repair': 'Describe your requests for repair',
//   Images: 'Images',
//   'Images are really important for this process': 'Images are really important for this process',
//   estimation_request_form_image_hint: 'Images are really important for this process. Please attach clear and specific photos of the flaw.',
//   'Upload Image': 'Attach Images',
//   'Send Request': 'Send Request',
//   // from here only non-obvious English keys
//   'Your request for a personal offer has been sent': 'Your request for a personal offer has been sent',
//   'Together with our tailors and shoemakers':
//     'Together with our tailors and shoemakers, we will now see what we can do for your favourite piece and will contact you soon',
//   'Book further repair': 'Book further repair',
//   'Back to homepage': 'Back to homepage',
//   Description: 'Description',
//   Title: 'Title',
//   sizeError: 'The upload file size is larger than 50mb. Use another image',
//   Name: 'Name',
//   Email: 'Email',
//   Gallery: 'Gallery',
//   'Contact Us': 'Contact Us',
//   Subject: 'Subject',
//   'Your phone number': 'Your phone number ( +4917669423504 )',
//   'Send Message': 'Send Message',
//   'Thank you Repair Rebel': 'Thank you,<0/> Repair Rebel!',
//   contact_form_hint: 'One of our agents will reach out to you as soon as possible.',
//   contact_form_banner_text: 'Your request has been submitted.',
//   order_status_view_hint: 'You’ll soon get an email confirmation with further details and instructions.',
//   'Your name': 'Your name',
//   'Your email address': 'Your email address',
//   'Your message': 'Your message',
//   'Send us an email': 'Send us an email',
//   'Sign In': 'Login',
//   Register: 'Register',
//   "Don't have an account yet?": "Don't have an account yet?",
//   Password: 'Password',
//   'Forgot your password?': 'Forgot your password?',
//   'Register now': 'Register',
//   Send: 'Send',
//   auth_password_reset_hint: 'We will send you an email with a new password to access your account',
//   Guest: 'Guest',
//   'First Name': 'First Name',
//   'Last Name': 'Last Name',
//   'Phone Number': 'Phone Number',
//   'Confirm Password': 'Confirm Password',
//   auth_register_submit_label: 'Register',
//   auth_register_login_heading: 'Already have an account?',
//   auth_register_heading: 'Register Now',
//   auth_register_join_club_label: 'I would like to join the Repair Rebel Loyalty Club',
//   auth_register_join_club_sublabel: 'Collect CO2 credits and receive discounts and vouchers',
//   auth_register_accept_terms_label: "I agree to Repair Rebels' <0>privacy policy</0>, <1>terms and conditions</1> and cancellation policy*",
//   fields_with_asterisks: 'Fields marked with asterisks (*) are required.',
//   newsletter_clarification:
//     '¹ You can unsubscribe from the newsletter at any time. By ticking the checkbox I agree that the data collected by me for the processing of my request will be collected and stored electronically. This consent can be revoked at any time by sending us a message. You can find further information in the data protection declaration.',
//   auth_forgot_password_success_text: 'A password reset link has been sent. To reset your password, please open the link and follow the instructions.',
//   'Change Password': 'Change Password',
//   'Current Password': 'Current Password',
//   'New Password': 'New Password',
//   Change: 'Change',
//   Code: 'Code',
//   Jobs: 'Jobs',
//   'Become a repair rebel': 'Become a repair rebel!',
//   'Password has been changed': 'Your password has been successfully changed!',
//   Close: 'Close',
//   Delete: 'Delete',
//   removeUserAccountDetails: 'You are going to completely remove your account. Tick the box to confirm your actions.',
//   'Your account has been successfully deleted': 'Your account has been successfully deleted. You will be redirected.',
//   'Are you sure': 'Are you sure',
//   'Add Address': 'Add Address',
//   'My Addresses': 'My Addresses',
//   'Current addresses': 'Current addresses',
//   Add: 'Add',
//   'Add New Payment Method': 'Add new payment method',
//   'Payment Methods': 'Payment methods',
//   'Card Number': 'Card number',
//   'Expiry Month': 'Expiry month',
//   'Expiry Year': 'Expiry year',
//   'Visa endings with': 'Visa endings with',
//   Edit: 'Edit',
//   product_further_details_frame_heading: 'Hey Rebel!<br />Toll, dass du eine Reparatur buchen möchtest!', // GERMAN
//   product_further_details_frame_hint: 'Wir optimieren grade unser online Buchungssystem. In der Zwischenzeit nehmen wir Bestellungen über WhatsApp an:', // GERMAN
//   'Because everyone and everything deserves a second chance': 'Because everyone and everything deserves a second chance',
//   'How was the tailors performance?': "How was {{tailorName}}'s performance?",
//   'Your comment': 'Your comment...',
//   'Please rate our service': 'Please rate our service',
//   'How were things managed by us?': 'How were things managed by us?',
//   'Rate your tailor(s)/cobbler(s)': 'Rate your tailor(s)/cobbler(s)', // ENGLISH
//   'There are no bookings': 'There are no bookings',
//   'There are no addresses': 'There are no addresses',
//   'Hey there, super rebel': 'Hey there, super rebel!',
//   'Your CO2 points balance': 'Your CO2 points balance:',
//   'You are part of our loyalty club': 'You are part of our loyalty club',
//   'Check your benefits': 'Check your benefits',
//   'Join our Rebel team!': 'Join our Rebel team!',
//   'Proceed to payment': 'Proceed to payment',
//   'Sign in': 'Login',
//   'Courier Service': 'Courier Service (free from 125€)',
//   'Packaging Cost': 'Packaging Cost',
//   'Final Price': 'Final Price',
//   inclVAT: 'incl. VAT',
//   'Order as a Guest': 'Order as a Guest',
//   Or: 'Or',
//   Location: 'Location',
//   'The idea of Repair Rebels was born from':
//     'The idea for Repair Rebels came from our love of fashion and the belief that our garments deserve more appreciation. Clothing can be more than a piece of fabric, because a piece of clothing can be associated with numerous memories and feelings. The true value of our clothing is not decided in store - we give it personal value by loving it and wearing it and having our experiences and adventures in it. Small flaws make them even more special and reflect our uniqueness.',
//   "That's why we want to rebel the fashion system":
//     'For this reason we want to rebel against the fashion system and not replace your favorite pieces, but repair them. Repair Rebels offers a platform that combines modern technology and traditional craftsmanship. We want to make the world a better place, by slowing down the fast-moving fashion industry and its overconsumption. Almost half of Germans have never had a piece of clothing or a pair of shoes repaired (Greenpeace, 2022). Instead, most consumers order new clothes online, replacing their favorite items. We want to promote appreciation for manual work and respect for things that we already own, support local crafts and thus make our contribution to a functioning circular economy.',
//   'Repairing is an attitude': 'Repairing is an attitude - a philosophy of life. If we can repair our clothes, we can repair almost anything in life!',
//   'Fast fashion was yesterday':
//     'Fast fashion was yesterday, slow fashion and repairs are the future. Repairs not only save us a lot of CO2 emissions and time, they are also cheaper and create local jobs. The longer you wear and keep your favorite sneakers, jeans, leather jacket or any other favorite item, the more memories you collect together and also make a style statement. We make textile and shoe repairs as fashionable and easy as buying new items - only cheaper, more sustainable and local. Repairing has never been so digital, so easy and so sexy. You can book your repairs from the comfort of your sofa. Your clothes will be picked up from you at a desired time and returned a few days later repaired. We offer a digital all-round service including collection and delivery. This makes Repair Rebels a real alternative to buying new!',
//   "Sounds good, doesn't it? Show love & repair your favorites!": 'Sounds good right? Show love & repair your favorites!',
//   'We should rethink our relationship...': 'We should rethink our relationship with our clothes and treat them like a second skin and rake care of them.',
//   'repair now!': 'repair now!',
//   'I want to join the super rebel loyalty club!': 'I want to join the super rebel loyalty club!',
//   timeGapNote: 'Please note that there must be 7 days between the pick up time and the delivery time. You may need to change the delivery time first.',
//   //Jobs page
//   'The idea of Repair Rebels was born out of a love for fashion...':
//     'The idea of Repair Rebels was born out of a love for fashion and a belief that our garments deserve more appreciation. We make our experiences and adventures in our clothes, so there can be numerous memories associated with a piece of clothing. This makes it all even more important to have our clothes rescued by a Local Hero. By doing so, we give each garment a second chance to accompany us on many more happy moments.',
//   "But of course, we don't accomplish all of this alone...":
//     "But of course, we don't accomplish all of this alone! If you want to be part of our mission, you're in the right place!",
//   'Are you looking for a dynamic and impact-driven company...':
//     'Are you looking for a dynamic and impact-driven company that wants to revolutionize the fashion industry and is constantly evolving? Then this is the right place for you! As part of our international and ambitious team, you will work on a pioneering solution to digitize the local craft industry and actively shape the future of the fashion industry.',
//   'Not in Düsseldorf?': 'Not in Düsseldorf?',
//   'Book your repairs via Estimate Request and send it to us via post': 'Book your repairs via Estimate Request and send it to us via post',
//   'Select the item for which you need a repair.': 'Select the item for which you need a repair.',
//   'Pick-up and delivery is free of charge for orders over 125€.': 'Pick-up and delivery in Düsseldorf is free for orders from 125€!',
//   'send us your question(s)': 'send us your question(s)',
//   "Send us a WhatsApp message and we'll see what we can do for you!": "Send us a WhatsApp message and we'll see what we can do for you!",
//   Dusseldorf: 'Düsseldorf',
//   'Please input a valid name': 'Please input a valid name',
//   'Email address cannot be empty': 'Email address cannot be empty',
//   'Please enter a valid email address': 'Please enter a valid email address',
//   'Email is already in the list': 'This email is already a Club member',
//   'Your invitation link has expired': 'Your invitation link has expired',
//   'Subject cannot be empty': 'Subject cannot be empty',
//   'Message cannot be empty': 'Message cannot be empty',
//   'Title cannot be empty': 'Title cannot be empty',
//   'Description cannot be empty': 'Description cannot be empty',
//   'Phone number cannot be empty': 'Phone number cannot be empty',
//   "The phone number is not valid, please use the '+4917669423506' format": "The phone number is not valid, please use the '+4917669423506' format",
//   "Please add the country code to your number in the '+49' format":
//     "Your number does not start with a '+' sign. Please note that you need to add the country code in the format '+{number}'. A German number, would for instance be written as follows '+4917669423506' ",
//   'This is not a valid number for the region.': 'This is not a valid number for the region: {{regionName}}',
//   'One piece that was waiting in my closet changed...':
//     'One piece that was waiting in my closet changed into two favourite pieces I wanna wear every day! I am very happy with the result of Repair Rebel’s work!! Can’t wait to let individualize another piece!',
//   'My old leather briefcases look like new...':
//     'My old leather briefcases look like new. Really great! And cheaper than buying a new one. Many thanks to the Repair Rebels team.',
//   'So pleased with the excellent repair of my...':
//     'So pleased with the excellent repair of my favourite alpaka wool jumper, which is a treasured souvenir from my sabbatical in Ecuador 10 years ago. It had some nasty wholes and now it is not even traceable where they were.',
//   'Your order cannot be edited withing 48 hours of the pickup or delivery date.':
//     'Your order cannot be edited withing 48 hours of the pickup or delivery date.',
//   'Please contact us via the': 'Please contact us via the',
//   'page to make an alternative arrangement': 'page to make an alternative arrangement',
//   'Please enter a valid zip code for city, or change your location in the User Account area.':
//     'Please use a valid zip code for city or change your location in the User Account area.',
//   'Please enter a valid zip code for your city':
//     'Currently we do not offer Door-to-Door delivery in your location. Please place your order via <0>”Estimate Request”</0> and send us your repairs via post!',
//   'First name required': 'First name required',
//   'Last name required': 'Last name required',
//   'Address required': 'Address required',
//   'The phone number is not valid, please use a valid format': "The phone number is not valid, please use the format: '+4917669423506'",
//   ...appProductsI18nEn,
//   ...appTailorsI18nEn,
// };

const appI18nEn = {
  'Unexpected error. Try again later or contact us': 'Unexpected error. Try again later or contact us',
  'Order access error': "Order not found, or you don't have permission to edit it. Please check if you're logged in to your account or contact us!",
  'Order rating access error': "Order not found, or you don't have permission to rate tailors. Please check if you're logged in to your account or contact us!",
  'Not Found': 'Not Found',
  aboutUs: '',
  'Go back': 'Go back',
  'Frequently Asked Questions': 'Frequently Asked Questions',
  'Our Supporters': 'Our Supporters',
  'Keep them and let us take care of them!': 'Keep them and let us take care of them!',
  'Still in love with your favourites?': 'Still in love with your favourites?',
  'Let us take cate of them!': 'LET US TAKE CARE OF THEM!',
  'Door to door deliveries': 'Door to door deliveries in',
  'We pick it up and we deliver it back': 'we pick it up and we deliver it back',
  'Tuesdays & Fridays': 'Tuesdays & Fridays',
  'Pick ups and deliveries <1>only in Düsseldorf</1>': 'Pick ups and deliveries <1>only in Düsseldorf</1>',
  'One week only!': 'One week only!',
  'Get your garment back within a week': 'Get your garment back within a week',
  'Loading...': 'Loading...',
  'Voucher': 'Voucher',
  'Add another voucher': 'Add another voucher',
  'Get estimation': 'Get estimation',
  'Get estimation description':
    'No suitable offer or do you have a special repair? Request an estimate now. We would like to take care of every favorite piece and send you an individual offer.',
  estimationDataPrivacy:
    'I expressly consent to the collection and processing of my personal data for the purpose of estimating the costs arising from the requested repair service. All information provided is voluntary. I have taken note of the information on data protection, which is available at the <0>link</0>*',
  ratingDataPrivacy:
    'I expressly consent to the collection, processing and publication of my personal data for the purpose of evaluating performance. All information provided is voluntary. I have taken note of the information on data protection, which is available on the <0>link</0>*',
  contactUsDataPrivacy:
    'I expressly consent to the collection and processing of my personal data for contact purposes. I have taken note of the information on data protection, which is available at the <0>link</0>.',
  "Let's get started!": "Let's get started!",
  'Choose the item to repair': 'Choose the item you need to repair',
  Dresses: 'Dresses',
  Trousers: 'Trousers',
  Tops: 'Tops',
  Jackets: 'Jackets',
  Sneakers: 'Sneakers',
  Bags: 'Bags',
  BookRepair: 'BOOK A REPAIR',
  GetAnEstimate: 'GET AN ESTIMATE',
  DorToDoor: 'DOOR-TO-DOOR DELIVERY IN DÜSSELDORF & COLOGNE',
  TypicalRepairTime: 'TYPICAL REPAIR TIME 7-10 DAYS',
  GermanWideShiping: 'GERMAN-WIDE SHIPPING BY POST',
  FreeTransport: 'FREE TRANSPORTATION FOR ORDERS FROM 145€',
  '<0><0>Good for the planet</0><1></1></0><1>& for your wallet</1>': '<0><0>Good for the planet</0><1></1></0><1>& for your wallet</1>',
  'Collect CO2 Credits and get discounts': 'Collect CO2 Credits and get discounts',
  'Learn More': 'Learn More',
  'Try it now!': 'Try it now!',
  '<0>Want to become a</0><1>Repair Rebel?</1>': '<0>Want to become a</0><1>Repair Rebel?</1>',
  'Why <1></1> Repair Rebels?': 'Why <1></1> Repair Rebels?',
  homePageLoyaltyClubBody:
    'Get practical care and repair tips, exclusive offers and collect CO2 credits. Sign up now for our newsletter and become part of the Super Rebel Loyalty Club.',
  homePageLoyaltyClubDescriptiveParagraphHeader: 'What you can expect',
  homePageLoyaltyClubDescriptiveParagraph:
    "Lots of useful tips & tricks on how to care for your beloved clothes, shoes and bags. Cool facts and information about new sustainable fashion brands and events in your neighbourhood. And of course, you will be rewarded for your loyalty and passion for repairs with discounts and loyalty points. Join us and don't miss out!",
  homePageLoyaltyClubCallToAction: 'Sign up now for our newsletter and become part of the Super Rebel Loyalty Club.',
  homePageLoyaltyClubQuote: 'YOU WANT TO BECOME A REAL REPAIR REBEL? THEN BECOME PART OF THE SUPER REBEL LOYALTY CLUB.',
  'Check your inbox for the invite email': 'Check your inbox for the invitation email',
  'Invite sent': 'Invitation sent',
  'You are subscribed': "Hooray! You have successfully subscribed to the Super Rebel Loyalty Club's Newsletter.",
  'Discounts & Packages': 'Discounts & Vouchers',
  'New Loyaolty Text': 'Erhalte praktische Tipps, exklusive Angebote und sammle CO2 Credits. Registriere Dich jetzt für unsere Newsletter und werde Teil des Super Rebel Loyalty Clubs.',
  'Check Box Loyalty Personal Data': 'I expressly consent to the collection and processing of my personal data. I have taken note of the privacy policy information accessible via the <0>link</0>.',
  'Join the club': 'Join the club',
  Back: 'Back',
  'Our Rebels': 'What our customers say',
  'See More': 'See More',
  'Meet our local partners': 'Meet our local partners',
  homePageBlogIntro:
    'Our local partners support our mission to give your clothes a second life, so you can wear them longer. Our carefully selected tailors, cobblers and goldsmiths are masters of their trade and show passion and love in every repair!',
  'Contact Information': 'Contact Information',
  'Contact Information - Bodytext 1':
    'We are on a mission, to re-define the textile and shoe repairs industry in a comfortable, social and modern way! For the environment, fashionable rebels and local craft talents.',
  'Contact Information - Contact Us': 'Contact us for more information:',
  'Contact Information Estimation': 'Contact Information',
  Imprint: 'Imprint',
  'Privacy Policy': 'Privacy Policy',
  'Terms and Conditions': 'Terms and Conditions',
  // Detailed image button
  from: 'from',
  'Main services': 'Main services',
  TransportationInfo: 'YOUR ADDRESS',
  Packaging: 'Packaging',
  MoreInfo: '+ More Info',
  FooterContent: 'REPAIR REBELS',
  'B2B Partnerships': 'B2B Partnerships',
  'LEGAL': 'LEGAL',
  Big: 'BIG',
  WhyRepairRebels: 'Why Repair Rebels?',
  Value: 'Value',
  LookingForYou: 'WE`VE BEEN LOOKING FOR YOU',
  'Looking Information': 'At Repair Rebels, we are passionate about fixing things and providing top-notch repair services to our customers. If you share our love for tinkering, problem-solving, and making a difference, we invite you to join our team. Together, we can revolutionize the world of repairs and create a lasting impact.',
  outside_information: 'If you are located outside of Germany, please contact us via the <0>Estimate Request</0> form',
  OrderSummary: 'ORDER SUMMARY',
  Address: 'Address',
  'Name for the Address': 'Name for the Address',
  'Pictures of his work': 'Pictures of his work',
  'Pictures of her work': 'Pictures of her work',
  'Good for the planet': 'Good for the planet',
  'Good for your wallet': 'Good for your wallet',
  'Good for the local economy': 'Good for the local economy',
  'CO2 Credits Per Garment': 'CO<sub>2</sub> Credits',
  Transportation: 'TRANSPORTATION',
  'Fees include two-way transportation': 'Fees include two-way transportation',
  'Transportation Modal - Information 1':
  'In case you choose to ship your repairs by post, we will send two shipping labels, you will need to pack your repairs in a box and bring it to the Deutsche Post office. After the repair work has been done, our local Repair Heros will ship them back to you.',
  'Transportation Modal - Information 2':
  'If you choose door-to-door delivery, Repair Rebels team member will collect your repairs from your home or office and deliver it back to you.',
  'Ship post price': '12.50 €',
  'Door to door price': '14.50 €',
  ShipByPost: 'I want to ship my repairs by post',
  BackToOrder: 'BACK TO ORDER',
  BookingDoorToDoor: 'I want to book door-to-door delivery',
  Garment: 'Garment',
  'CO2 Credits': 'CO<sub>2</sub> Credits',
  'Why CO2 Credits?': 'Why CO<sub>2</sub> Credits?',
  CO2_page_body: 'MISSING', // TEXT NEEDED
  'Start Repairing': 'Start Repairing',
  'START REPAIRING': 'START REPAIRING',
  'PRESS PACKAGE': 'PRESS PACKAGE',
  'PRESS TEXT': 'WANT TO LEARN MORE ABOUT OUR WORK? CHECK OUT OUR PRESS PACKAGE WHERE YOU WILL FIND OUR PRESS RELEASES AND PICTURES',
  'Join the Club': 'Join the Club',
  'How It Works': 'How It Works',
  Repairing: 'Repair',
  'Tips & More': 'Tips & More',
  'About Us': 'About Us',
  Tailors: 'Our Tailors',
  RepairHeroes: 'Repair Heroes',
  CraftsmenCooperations: 'CRAFTSMEN COOPERATIONS',
  'Join Network': 'JOIN OUR NETWORK OF CRAFTSMEN',
  'Craftsmen Page Main text p1': 'At Repair Rebels, skilled and motivated craftsmen are key to our mission of promoting local craftsmanship and sustainability. We connect you with repair projects that fit your skills and schedule, offering an environment where you can grow and thrive.',
  'Craftsmen Page Main text p2': 'Are you among the best in your field and ready to join top craftsmen in combating fast fashion? Become part of the future of fashion and help us build a community dedicated to quality repairs!',
  'PageQuote': '“Die Idee ist wirklich prima - man erreicht mit dem digitalen Service Leute, die sonst nichet zu den normalen Öffnungszeiten kommen können, und kann auf diese Weise neue Kundengruppen an sich binden.”',
  'QuoteSignature': 'Karina Ranft, Schuhmacherin für Repair Rebels Werkstatt 2023',
  'RunningLineItem-1': 'WE ARE LOOKING FOR COBBLERS',
  'RunningLineItem-2': 'TAILORS ',
  'RunningLineItem-3': 'GOLDSMITH',
  'RunningLineItem-4': 'UPCYCLING DESIGNERS ',
  'RunningLineItem-5': 'FURNITURE RESTORERS',
  'RunningLineItem-6': 'SADDLERS',
  'RunningLineItem-7': 'Workshop Hosts',
  'How It Works p-1': 'Our online service manages repair shipping across Germany, allowing users to book repairs from home. With Repair Rebels, repairing cherished garments is a real alternative to buying new ones.',
  'How It Works p-2': 'Fast fashion is out; slow fashion and repairs are in. Repairing favorites saves time, cuts CO2 emissions, and creates local jobs.',
  'How It Works p-3': 'With evolving European Green Deal and ecodesign regulations, we’re building a community of top craft artisans!',
  'Did you know the repair market is expecting a 4,3% growth by 2030?': 'Did you know the repair market is expecting a 4,3% growth by 2030?',
  'HOW IT WORKS': 'HOW IT WORKS',
  'GET IN TOUCH': 'GET IN TOUCH',
  'Stay up-to-date with the latest news in the repair industry subscribe now to our newsletter, with no strings attached!': 'Stay up-to-date with the latest news in the repair industry: subscribe now to our newsletter, with no strings attached!',
  'You are p-1': 'A tailor, a cobbler, a goldsmith, or an upcycling artist, very experienced with repairs.',
  'You are p-2': 'The owner of your own studio/shop within Germany with the mechanical equipment for your area.',
  'You are p-3': 'Eager to be part of an industry-changing community and want to make your business more digital and efficient.',
  'You are p-4': 'Passionate with your craft and want to share it, dealing with people is easy for you.',
  'ABOUT REPAIR REBELS': 'ABOUT REPAIR REBELS',
  'MEET OUR REPAIR HEROS': 'MEET OUR REPAIR HEROS',
  'You have successfully subscribed!': 'You have successfully subscribed!',
  'Mission of Repair Rebels': 'Repair Rebels is on a mission to make textile and shoe repairs digital, convenient, and fashionable! To achieve this, we are building a unique craftsmen infrastructure and specializing in reverse logistics processes and software. From textiles to shoes, bags, and jewelry, we offer our customers high-quality service and a seamless digital experience along the entire value chain.',
  OurRepairHeroes: 'Our Repair Heroes',
  'EACH AND EVERY ONE BRINGS EXPERTISE AND PASSION FOR THEIR CRAFT': 'EACH AND EVERY ONE BRINGS EXPERTISE AND PASSION FOR THEIR CRAFT',
  'Repair Heroes Subtitle': 'At Repair Rebels, we believe in the art of repair and the power of community. If you are interested in joining our network of local repair heroes, learn how you can become part of the movement here!',
  'JOIN THE NETWORK': 'JOIN THE NETWORK',
  ImageProductUploadText: 'Optional: To help us assign the best suited repair artist, please upload a few pictures or videos.',
  PricingGuide: 'PRICING GUIDE',
  'Pricing Guide Text': "Dear Rebels, please be informed that the prices you see here are the minimum prices for standard repairs. Because every repair is unique, and we always strive for a perfect outcome, the final cost might vary. If you did not find a suitable offer or you have a very special repair, then fill in the <0>price estimate</0> form. We will provide you with an individual quote once we've reviewed your repair request.",
  Contact: 'Contact',
  'Select the item type': 'Select the item type', // used in tests
  'Select the type of repair': 'Select the type of repair', // used in tests
  'Further Details': 'Further Details', // used in tests
  'Successfully added to cart': 'Successfully added to the cart!', // used in tests
  'Select the type of service': 'Select the type of service',
  'Send us your request': 'Send us your request',
  'Add to Cart': 'Add to Cart',
  Adding: 'Adding',
  Comments: 'Comments',
  'Type of Repair': 'Type of Repair',
  'Item Type': 'Item Type',
  product_estimation_hint:
    "Did not find a suitable offer or do you have a very special repair? Request an estimate now. From grandma's coat to your camping tent, we will look at each request individually and select a suitable repair artist.",
  'Garment Description': 'Garment Description',
  'Describe your requests for repair': 'Describe your requests for repair',
  Images: 'PICTURES & VIDEOS',
  'Images are really important for this process': 'High quality pictures and videos are essential for our team to assess your request accurately and assign the best suitable repair artist. Please ensure you provide detailed information about the damaged area as well as the entire item.',
  estimation_request_form_image_hint: 'High quality pictures and videos are essential for our team to assess your request accurately and assign the best suitable repair artist. Please ensure you provide detailed information about the damaged area as well as the entire item.',
  'Upload Image': 'Attach Files',
  'ATTACHED FILES': 'ATTACHED FILES',
  'Send Request': 'Send Request',
  // from here only non-obvious English keys
  'Your request for a personal offer has been sent': 'Your request for a personal offer has been sent',
  'Together with our tailors and shoemakers':
    'Together with our tailors and shoemakers, we will now see what we can do for your favourite piece and will contact you soon',
  'Book further repair': 'Book further repair',
  'Back to homepage': 'Back to homepage',
  Description: 'Description',
  Title: 'Title',
  sizeError: 'The upload file size is larger than 50mb. Use another image',
  cityDeliveryError: 'Select a city for door-to-door delivery',
  Name: 'Name',
  Subscribe: 'SUBSCRIBE',
  weOfferBrands: 'WE OFFER BRANDS AND RETAILERS',
  Email: 'Email',
  Gallery: 'Gallery',
  'Contact Us': 'Contact Us',
  Subject: 'Subject',
  'Your phone number': 'Your phone number ( +4917669423504 )',
  'Send Message': 'Send Message',
  'Thank you Repair Rebel': 'Thank you,<0/> Repair Rebel!',
  contact_form_hint: 'One of our agents will reach out to you as soon as possible.',
  contact_form_banner_text: 'Your request has been submitted.',
  order_status_view_hint: 'You’ll soon get an email confirmation with further details and instructions.',
  'Your name': 'Your name',
  'Your email address': 'Your email address',
  'Your message': 'Your message',
  'Send us an email': 'Send us an email',
  Login: 'Login',
  Register: 'Register',
  "Don't have an account yet?": "Don't have an account yet?",
  "Don't have any account yet?": "DON`T HAVE ANY ACCOUNT YET?",
  'Easily track your orders and store receipts': 'Easily track your orders and store receipts',
  'Receive exclusive offers and discounts': 'Receive exclusive offers and discounts',
  'Continue as a guest': 'Continue as a guest',
  Password: 'Password',
  'Forgot your password?': 'Forgot your password?',
  'Create password': 'Create password',
  'Register now': 'Register',
  Send: 'Send',
  auth_password_reset_hint: 'We will send you an email with a new password to access your account',
  Guest: 'REBEL!',
  'First Name': 'First Name',
  'Last Name': 'Last Name',
  'Phone Number': 'Phone Number',
  Location: 'Location',
  Country: 'Country',
  City: 'City',
  StreetAndHouse: 'Street and house number',
  YourBrand: 'YOUR BRAND',
  'Collaborate with brands': 'We are excited to collaborate with brands and retailers, who share our passion for sustainable fashion.',
  CorporatePartnerships: 'CORPORATE PARTNERSHIPS',
  GetInTouch: 'GET IN TOUCH',
  Street: 'Street',
  'Zip code': 'Zip code',
  'Confirm Password': 'Confirm Password',
  auth_register_submit_label: 'Register',
  auth_register_login_heading: 'Already have an account?',
  auth_register_heading: 'Register Now',
  auth_register_join_club_label: 'I would like to join the Repair Rebel Loyalty Club',
  auth_register_join_club_sublabel: 'Collect CO2 credits and receive discounts and vouchers',
  auth_register_accept_terms_label: "I agree to Repair Rebels' <0>privacy policy</0>, <1>terms and conditions</1> and cancellation policy*",
  fields_with_asterisks: 'Fields marked with asterisks (*) are required.',
  newsletter_clarification:
    '¹ You can unsubscribe from the newsletter at any time. By ticking the checkbox I agree that the data collected by me for the processing of my request will be collected and stored electronically. This consent can be revoked at any time by sending us a message. You can find further information in the data protection declaration.',
  auth_forgot_password_success_text: 'A password reset link has been sent. To reset your password, please open the link and follow the instructions.',
  'Change Password': 'Change Password',
  'Current Password': 'Current Password',
  'New Password': 'New Password',
  Change: 'Change',
  Code: 'Code',
  ForCraftsmen: 'For Craftsmen',
  DownloadReceipt: 'Download Receipt',
  OrderReceipts: 'Repair Service - Delivery',
  ShippingReceipts: 'Repair Service - Shipping',
  VoucherReceipts: 'Gift Vouchers',
  MenuVouchers: 'Gift Vouchers',
  Partnerships: 'PARTNERSHIPS',
  'BRAND PARTNERSHIPS': 'BRAND PARTNERSHIPS',
  'Brand subtitle': 'BE AHEAD OF THE GAME: JOIN THE CIRCULAR FASHION MOVEMENT!',
  'Brand text': 'Our award-winning circular fashion service offers end-to-end, customizable, and scalable solutions for your brand. Join our network of fashion experts, innovators, and change-makers, and explore new business opportunities with our digital repair booking portal and local in-store solutions. Now available across Germany and expanding throughout Europe.',
  'Join ecosystem title': 'Join our ecosystem of fashion innovators and change-makers',
  'Ecosystem text': 'As pioneers in the German market, Repair Rebels offer consultation and customised solutions for your repair, upcycling and local on demand production service needs. Well-connected to spare part suppliers, NGOs, leading academic institutions, chambers of crafts, and the global startup ecosystem, we are here to help you meet the upcoming EU Ecodesign Regulations and implement a well-rounded and profitable circular business strategy.',
  'THE REPAIR REBELS EXPERTISE': 'THE REPAIR REBELS EXPERTISE',
  'Expertis text': 'Repair Rebels is on a mission to make textile and shoe repairs digital, convenient, and fashionable! To achieve this, we are building a unique craftsmen infrastructure and specializing in reverse logistics processes and software. From textiles to shoes, bags, and jewelry, we offer our customers high-quality service and a seamless digital experience along the entire value chain.',
  'You Are': 'You Are',
  'Sustainably driven fashion brand': "Sustainably driven fashion brand",
  'Fashion store or online shop': 'Fashion store or online shop',
  'Vintage or second-hand shop': 'Vintage or second-hand shop',
  'Fashion care & repair product supplier': 'Fashion care & repair product supplier',
  'REQUEST A PERSONAL CONSULTATION': 'REQUEST A PERSONAL CONSULTATION',
  '...BECAUSE THERE IS NO CIRCULAR FASHION WITHOUT CARE & REPAIR!': '...BECAUSE THERE IS NO CIRCULAR FASHION WITHOUT CARE & REPAIR!',
  SeoPartnership: 'Brand Partnership',
  SeoPartneshipDescriotion: 'Join our network of fashion experts, innovators, and change-makers, and explore new business opportunities with our digital repair booking portal and local in-store solutions.',
  GiftVouchers: 'GIFT VOUCHERS',
  'Looking for a unique and meaningful': 'Looking for a unique and meaningful gift for your loved ones?',
  'A small gift with': 'A small gift with a big impact! By choosing to repair we are not only doing something good for our favorite fashion items, we are also protecting the environment and supporting the local craftsmanship.',
  'A GIFT OF REPAIR SHOWS THAT YOU CARE!': 'A GIFT OF REPAIR SHOWS THAT YOU CARE!',
  'How does It Work?': 'How Does It Work?',
  'The Repair Rebels repair voucher': 'The Repair Rebels repair voucher is the perfect gift for your loved ones. After completing the booking, you will receive the voucher with a unique code via email. You can choose to either print and give the voucher yourself or forward it via email. Our gift cards can only be redeemed online and are valid for 3 years. The expiry date is the end of the year in which the gift voucher was purchased.',
  'By the way': 'By the way: The voucher is only sent via email, not by post. Also, when booking a repair, the entire value of the voucher must be used.',
  'LET’S REPAIR THE FUTURE TOGETHER!': 'LET’S REPAIR THE FUTURE TOGETHER!',
  'EXPLORE NEW BUSINESS OPPORTUNITIES': 'EXPLORE NEW BUSINESS OPPORTUNITIES',
  'TRANSITION TO CIRCULAR ECONOMY': 'TRANSITION TO CIRCULAR ECONOMY',
  'SET INDUSTRY STANDARDS': 'SET INDUSTRY STANDARDS',
  'EMBRACE COLLABORATIVE INNOVATION': 'EMBRACE COLLABORATIVE INNOVATION',
  'SUPPORT GERMAN CRAFTSMANSHIP': "SUPPORT GERMAN CRAFTSMANSHIP",
  BoxesAre: 'Bags Are',
  Jobs: 'Jobs',
  'Become a repair rebel': 'WERDE TEIL DES REPAIR REBELS TEAMS!',
  'Password has been changed': 'Your password has been successfully changed!',
  Close: 'Close',
  TailorClubTitle: 'CRAFTSMEN COMMUINITY',
  'Successful subscribed to craftsmen newsletter': 'You have successfully subscribed to our Craftsmen Newsletter – get ready for updates and exciting news!',
  ErrorCustomClubMessage: 'THIS EMAIL HAS BEEN USED ALREADY',
  MainModalTitle: 'JOIN THE REPAIR REVOLUTION!',
  'Modal Content Top': 'For practical tips, exclusive events and special offers, register now for our newsletter and become part of the Super Rebel Loyalty Club.',
  'Modal Content Bottom': 'Start saving and receive a <0>5% discount</0> code for your first repair booking with us',
  'Modal Check Box Text': 'I have taken note of the information on data protection, which is available under the <0>link</0>.',
  'YOUR INVITATION HAS BEEN SENT!': 'YOUR INVITATION HAS BEEN SENT!',
  'Check your inbox for the invitation email': 'Thank you check your inbox for the invitation email',
  'Registrieren': 'REGISTER NOW',
  'BOOK A REPAIR': 'BOOK A REPAIR',
  CategoryVoucher: 'CATEGORY: VOUCHER',
  PriceGuide: 'Pricing Guide',
  Delete: 'Delete',
  removeUserAccountDetails: 'You are going to completely remove your account. Tick the box to confirm your actions.',
  'Your account has been successfully deleted': 'Your account has been successfully deleted. You will be redirected.',
  'Are you sure': 'Are you sure',
  'Add Address': 'Add Address',
  'My Addresses': 'My Addresses',
  'Current addresses': 'Current addresses',
  Add: 'Add',
  'Add New Payment Method': 'Add new payment method',
  'Payment Methods': 'Payment methods',
  'Card Number': 'Card number',
  'Expiry Month': 'Expiry month',
  'Expiry Year': 'Expiry year',
  'Visa endings with': 'Visa endings with',
  Edit: 'Edit',
  ChooseProduct: '1. Choose Product',
  'Description About Product': 'All things fashion can be repaired! Choose the type of product which needs a repair or some extra love.',
  ChooseRepair: '2. Choose repair',
  'Description About Repair': "Choose from a list of repair, alteration, or restoration services. If you don't find a suitable one, send us an estimate request.",
  ChooseDelivery: '3. CHOOSE DELIVERY',
  'Description About Delivery': 'We offer a secure German-wide shipping option or a personal door-to-door delivery service within Düsseldorf & Cologne.',
  BecomeRebel: '4. BECOME A REBEL',
  'Description About Rebel': 'Create a user account & join the Super Rebel Loyalty Club. Track your orders and enjoy special benefits.',
  ReceiveOrder: '5. RECEIVE YOUR ORDER',
  'Description About Order': 'Receive your repaired favorite pieces within 7-14 days. Repeat the process with more fashion items & share your experience with friends!',
  product_further_details_frame_heading: 'Hey Rebel!<br />Toll, dass du eine Reparatur buchen möchtest!', // GERMAN
  product_further_details_frame_hint: 'Wir optimieren grade unser online Buchungssystem. In der Zwischenzeit nehmen wir Bestellungen über WhatsApp an:', // GERMAN
  'Because everyone and everything deserves a second chance': 'Because everyone and everything deserves a second chance',
  'How was the tailors performance?': "How was {{tailorName}}'s performance?",
  'How was the tailors unpublish performance?': "How was performance?",
  'How happy are you with the repair?': "HOW HAPPY ARE YOU WITH THE {{repairProducts}} REPAIR?",
  'Your comment': 'Your comment...',
  'Please rate our service': 'Please rate our service',
  'How were things managed by us?': 'How were things managed by us?',
  'Rate your tailor(s)/cobbler(s)': 'Rate your tailor(s)/cobbler(s)', // ENGLISH
  'There are no bookings': 'There are no bookings',
  'There are no addresses': 'There are no addresses',
  'Hey there, super rebel': 'Hey there, super rebel!',
  'Your CO2 points balance': 'Your CO2 points balance:',
  'You are part of our loyalty club': 'You are part of our loyalty club',
  'Check your benefits': 'Check your benefits',
  'Join our Rebel team!': 'Join our Rebel team!',
  'Proceed to payment': 'Proceed to payment',
  'Courier Service': 'Transportation Service (free from 145€)',
  'Packaging Cost': 'Packaging Cost',
  'Final Price': 'Final Price',
  'Product Cost': 'Product Cost',
  'Shipping': 'Shipping',
  inclVAT: '(incl. VAT)',
  'Order as a Guest': 'Order as a Guest',
  Or: 'Or',
  'The idea of Repair Rebels was born from':
    'The idea for Repair Rebels came from our love of fashion and the belief that our garments deserve more appreciation. Clothing can be more than a piece of fabric, because a piece of clothing can be associated with numerous memories and feelings. The true value of our clothing is not decided in store - we give it personal value by loving it and wearing it and having our experiences and adventures in it. Small flaws make them even more special and reflect our uniqueness.',
  "That's why we want to rebel the fashion system":
    'For this reason we want to rebel against the fashion system and not replace your favorite pieces, but repair them. Repair Rebels offers a platform that combines modern technology and traditional craftsmanship. We want to make the world a better place, by slowing down the fast-moving fashion industry and its overconsumption. Almost half of Germans have never had a piece of clothing or a pair of shoes repaired (Greenpeace, 2022). Instead, most consumers order new clothes online, replacing their favorite items. We want to promote appreciation for manual work and respect for things that we already own, support local crafts and thus make our contribution to a functioning circular economy.',
  'Repairing is an attitude': 'Repairing is an attitude - a philosophy of life. If we can repair our clothes, we can repair almost anything in life!',
  'Fast fashion was yesterday':
    'Fast fashion was yesterday, slow fashion and repairs are the future. Repairs not only save us a lot of CO2 emissions and time, they are also cheaper and create local jobs. The longer you wear and keep your favorite sneakers, jeans, leather jacket or any other favorite item, the more memories you collect together and also make a style statement. We make textile and shoe repairs as fashionable and easy as buying new items - only cheaper, more sustainable and local. Repairing has never been so digital, so easy and so sexy. You can book your repairs from the comfort of your sofa. Your clothes will be picked up from you at a desired time and returned a few days later repaired. We offer a digital all-round service including collection and delivery. This makes Repair Rebels a real alternative to buying new!',
  "Sounds good, doesn't it? Show love & repair your favorites!": 'Sounds good right? Show love & repair your favorites!',
  'We should rethink our relationship...': 'We should rethink our relationship with our clothes and treat them like a second skin and rake care of them.',
  'repair now!': 'repair now!',
  'I want to join the super rebel loyalty club!': 'I want to join the super rebel loyalty club!',
  timeGapNote: 'Please note that there must be 7 days between the pick up time and the delivery time. You may need to change the delivery time first.',
  //Jobs page
  'The idea of Repair Rebels was born out of a love for fashion...':
    'The idea of Repair Rebels was born out of a love for fashion and a belief that our garments deserve more appreciation. We make our experiences and adventures in our clothes, so there can be numerous memories associated with a piece of clothing. This makes it all even more important to have our clothes rescued by a Local Hero. By doing so, we give each garment a second chance to accompany us on many more happy moments.',
  "But of course, we don't accomplish all of this alone...":
    "But of course, we don't accomplish all of this alone! If you want to be part of our mission, you're in the right place!",
  'Are you looking for a dynamic and impact-driven company...':
    'Are you looking for a dynamic and impact-driven company that wants to revolutionize the fashion industry and is constantly evolving? Then this is the right place for you! As part of our international and ambitious team, you will work on a pioneering solution to digitize the local craft industry and actively shape the future of the fashion industry.',
  'Not in Düsseldorf?': 'Not in Düsseldorf?',
  'Book your repairs via Estimate Request and send it to us via post': 'Book your repairs via Estimate Request and send it to us via post',
  'Select the item for which you need a repair.': 'Select the item for which you need a repair.',
  'Pick-up and delivery is free of charge for orders over 125€.': 'Transportation is free for orders from 145€',
  'send us your question(s)': 'send us your question(s)',
  "Send us a WhatsApp message and we'll see what we can do for you!": "Send us a WhatsApp message and we'll see what we can do for you!",
  Cologne: 'Cologne',
  Dusseldorf: 'Dusseldorf',
  'Please input a valid name': 'Please input a valid name',
  'Email address cannot be empty': 'Email address cannot be empty',
  'Please enter a valid email address': 'Please enter a valid email address',
  'Your account has been blocked': 'Your account has been blocked. Check your email for verification message or contact our support team.',
  'Email is already in the list': 'This email is already a Club member',
  'Your invitation link has expired': 'Your invitation link has expired',
  'Subject cannot be empty': 'Subject cannot be empty',
  'Message cannot be empty': 'Message cannot be empty',
  'Title cannot be empty': 'Title cannot be empty',
  'Description cannot be empty': 'Description cannot be empty',
  'Phone number cannot be empty': 'Phone number cannot be empty',
  "The phone number is not valid, please use the '+4917669423506' format": "The phone number is not valid, please use the '+4917669423506' format",
  "Please add the country code to your number in the '+49' format":
    "Your number does not start with a '+' sign. Please note that you need to add the country code in the format '+{number}'. A German number, would for instance be written as follows '+4917669423506' ",
  'This is not a valid number for the region.': 'This is not a valid number for the region: {{regionName}}',
  'One piece that was waiting in my closet changed...':
    'One piece that was waiting in my closet changed into two favourite pieces I wanna wear every day! I am very happy with the result of Repair Rebel’s work!! Can’t wait to let individualize another piece!',
  'My old leather briefcases look like new...':
    'My old leather briefcases look like new. Really great! And cheaper than buying a new one. Many thanks to the Repair Rebels team.',
  'So pleased with the excellent repair of my...':
    'So pleased with the excellent repair of my favourite alpaka wool jumper, which is a treasured souvenir from my sabbatical in Ecuador 10 years ago. It had some nasty wholes and now it is not even traceable where they were.',
  'Your order cannot be edited withing 48 hours of the pickup or delivery date.':
    'Your order cannot be edited withing 48 hours of the pickup or delivery date.',
  'Please contact us via the': 'Please contact us via the',
  'page to make an alternative arrangement': 'page to make an alternative arrangement',
  'Please enter a valid zip code for city, or change your location in the User Account area.':
    'Please use a valid zip code for city or change your location in the User Account area.',
  'Please enter a valid zip code for your city':
    'Currently we do not offer Door-to-Door delivery in your location. Please place your order via <0>”Estimate Request”</0> and send us your repairs via post!',
  'First name required': 'First name required',
  'Please enter a valid country': 
  'For orders outside of Germany, please contact us via the Estimate Request form.',
  'Last name required': 'Last name required',
  'Address required': 'Address required',
  'The phone number is not valid, please use a valid format': "The phone number is not valid, please use the format: '+4917669423506'",
  'faq-header': 'THIS IS HOW OUR SERVICE WORKS',
  'faq-subtext': 'Here you will find all the important information about our online fashion repair service.',
  'blog-header': 'OUR BLOGPOSTS',
  'blog-subheader': 'Find out useful repair tips and be the first to hear the news about sustainable brands and the slow fashion movement!',
  'imprint-header': 'IMPRESSUM',
  'privacy-policy-header': 'DATENSCHUTZERKLÄRUNG',
  'terms-header': 'ALLGEMEINE GESCHÄFTSBEDINGUNGEN',
  'jobs-header': 'WERDE TEIL DES REPAIR REBELS TEAMS!',
  'tailors-list-header': 'UNSERE PARTNER VOR ORT',
  'tailors-list-subheader':
    'Unsere erfahrenen Local Heroes machen alles möglich. Jeder von ihnen hat seine eigene Spezialität und bringt Erfahrungen aus aller Welt mit. Unsere Schneider, Schuster und Goldschmiede können jedes Modestück reparieren!',
  'We work with local repair shops':
    'We work with local repair shops. They usually specialize in repairing shoes and bags or clothing. We therefore ask that if you would like to have both shoes (or bags) and clothing repaired, please hand them in in separate bags.',
  ...appProductsI18nEn,
  ...appTailorsI18nEn,
  ...seoI18nEn,
};

export default appI18nEn;
