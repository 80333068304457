import pcIcon from '../assets/PC.svg'
import collaborationIcon from '../assets/collaboration.svg'
import seamstressIcon from '../assets/seamstress.svg'
import recycleIcon from '../assets/recycle.svg'

export const craftsmenBenefitsDataEn = [
    {
        icon: collaborationIcon,
        textZone: 400,
        title: 'EXPAND YOUR CUSTOMER BASE',
        text: 'Reach new customers and enjoy quality assurance as a Repair Rebels partner—completely free.'
    },
    {
        icon: seamstressIcon,
        textZone: 450,
        title: 'FACE EXCITING REPAIRS',
        text: 'Receive tailored repair projects that match your skills, work with renowned brands and retailers, and join exclusive workshop opportunities.'
    },
    {
        icon: pcIcon,
        textZone: 400,
        title: 'BENEFIT OF OUR ONLINE VISIBILITY',
        text: 'With your profile displayed on our website and social media, promote your expertise and the locality of your craft.'
    },
    {
        icon: recycleIcon,
        textZone: 400,
        title: 'STAY UP TO DATE IN THE INDUSTRY',
        text: 'Subscribe to our newsletter for the latest repair news and join our network of innovators.'
    },
]

export const craftsmenBenefitsDataDe = [
    {
        icon: collaborationIcon,
        textZone: 400,
        title: 'erweiterte kundenkartei',
        text: 'Erreichen Sie neue Kunden und nutzen Sie unsere Qualitätsgarantie – kostenlos als offizieller Partner von Repair Rebels.'
    },
    {
        icon: seamstressIcon,
        textZone: 400,
        title: 'SPANNENDE REPARATUREN',
        text: 'Erhalten Sie maßgeschneiderte Reparaturprojekte, arbeiten Sie mit renommierten Marken und Einzelhändlern zusammen und nehmen Sie an exklusiven Workshops teil.'
    },
    {
        icon: pcIcon,
        textZone: 450,
        title: 'erhöhte online präsenz',
        text: 'Präsentieren Sie Ihre Expertise und Regionalität durch die Anzeige Ihres Profils auf unserer Website und in sozialen Medien.'
    },
    {
        icon: recycleIcon,
        textZone: 400,
        title: 'BLEIBEN SIE AUF DEM LAUFENDEN',
        text: 'Abonnieren Sie unseren Newsletter für Updates aus der Reparaturbranche und werden Sie Teil unseres Netzwerks von Wegbereitern!'
    },
]